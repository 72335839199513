export const environment = {
  production: false,
  instance: 'https://login.microsoftonline.com/',
  clientId: 'e140b871-dced-46f3-bc27-62d1d7fca8b4',
  tenantId: 'common',
  authority: 'https://login.microsoftonline.com/common',
  ishtarFunctions: 'https://functions.test.ishtar365.com',
  ishtarFunctionsKey:
    'LCUFHqZOIDW35Sb1Z2RfxGhBByhwb_bZgvJzbuEeyyybAzFu2pLmFQ==',
  ishtar365: 'https://portal.test.ishtar365.com',
  appIdentifier: 'resourceplanning.test',
  cdnAppIconUrl:
    'https://ishtar365storage.blob.core.windows.net/icons/Resource_color.svg',
  ishtarFunctionsScope:
    'api://e140b871-dced-46f3-bc27-62d1d7fca8b4/user_impersonation',
};
